import "./MobileMenu.css";
import menuOpen from "../../assets/newadmin/misc/menu.png";
import menuClose from "../../assets/newadmin/misc/Close_round.png";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

function MobileMenu() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  //   console.log(location);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <div className="mobile__menu">
      <div className="mobile__header">
        <p>MarkAi</p>{" "}
        <img src={menuOpen} alt="menuOpen" onClick={handleMenuToggle} />
      </div>
      {isMenuOpen ? (
        <div className="mobiledropdown__menu open close">
          <img src={menuClose} alt="close" onClick={handleMenuToggle} />
          <ul>
            <li
              style={
                location.pathname === "/admin"
                  ? {
                      transform: "scale(1.3)",
                      borderLeft: " 2px solid var(--chart-green)",
                      opacity: "1",
                    }
                  : {}
              }
            >
              <Link
                to="/admin"
                onClick={() => {
                  handleMenuToggle();
                }}
                style={{ color: "var(--font-color)", textDecoration: "none" }}
              >
                Home
              </Link>
            </li>
            <li
              style={
                location.pathname === "/admin/competitors"
                  ? {
                      transform: "scale(1.3)",
                      borderLeft: " 2px solid var(--chart-green)",
                      opacity: "1",
                    }
                  : {}
              }
            >
              <Link
                to="/admin/competitors"
                onClick={() => {
                  handleMenuToggle();
                }}
                style={{ color: "var(--font-color)", textDecoration: "none" }}
              >
                Competitors
              </Link>
            </li>
            <li
              style={
                location.pathname === "/admin/keywords"
                  ? {
                      transform: "scale(1.3)",
                      borderLeft: " 2px solid var(--chart-green)",
                      opacity: "1",
                    }
                  : {}
              }
            >
              <Link
                to="/admin/keywords"
                onClick={() => {
                  handleMenuToggle();
                }}
                style={{ color: "var(--font-color)", textDecoration: "none" }}
              >
                Keywords
              </Link>
            </li>
            <li
              style={
                location.pathname === "/admin/targetaudience"
                  ? {
                      transform: "scale(1.3)",
                      borderLeft: " 2px solid var(--chart-green)",
                      opacity: "1",
                    }
                  : {}
              }
            >
              <Link
                to="/admin/targetaudience"
                onClick={() => {
                  handleMenuToggle();
                }}
                style={{ color: "var(--font-color)", textDecoration: "none" }}
              >
                Target Audience
              </Link>
            </li>
            <li
              style={
                location.pathname === "/admin/settings"
                  ? {
                      transform: "scale(1.3)",
                      borderLeft: " 2px solid var(--chart-green)",
                      opacity: "1",
                    }
                  : {}
              }
            >
              <Link
                to="/admin/settings"
                onClick={() => {
                  handleMenuToggle();
                }}
                style={{ color: "var(--font-color)", textDecoration: "none" }}
              >
                Settings
              </Link>
            </li>
            <li
              style={
                location.pathname === "/admin/support"
                  ? {
                      transform: "scale(1.3)",
                      borderLeft: " 2px solid var(--chart-green)",
                      opacity: "1",
                    }
                  : {}
              }
            >
              <Link
                to="/admin/support"
                onClick={() => {
                  handleMenuToggle();
                }}
                style={{ color: "var(--font-color)", textDecoration: "none" }}
              >
                Support
              </Link>
            </li>
          </ul>
        </div>
      ) : null}
    </div>
  );
}

export default MobileMenu;
