import "./SkeletonElement.css";
import SkeletonElement from "./SkeletonElement";
import SkeletonElement2 from "./SkeletonElement2";

const SkeletonChart = () => {
  const minimum = -10;
  return (
    <div className="skeleton__wrapper">
      <div className="skeleton__chart">
        <SkeletonElement
          type="title"
          barHeight={Math.floor(Math.random() * (100 - minimum)) + "%"}
        />
        <SkeletonElement
          type="text"
          barHeight={Math.floor(Math.random() * (100 - minimum)) + "%"}
        />
        <SkeletonElement
          type="title"
          barHeight={Math.floor(Math.random() * (100 - minimum)) + "%"}
        />
        <SkeletonElement
          type="text"
          barHeight={Math.floor(Math.random() * (100 - minimum)) + "%"}
        />
        <SkeletonElement
          type="title"
          barHeight={Math.floor(Math.random() * (100 - minimum)) + "%"}
        />
        <SkeletonElement
          type="text"
          barHeight={Math.floor(Math.random() * (100 - minimum)) + "%"}
        />
        <SkeletonElement
          type="title"
          barHeight={Math.floor(Math.random() * (100 - minimum)) + "%"}
        />
        <SkeletonElement
          type="text"
          barHeight={Math.floor(Math.random() * (100 - minimum)) + "%"}
        />
        <SkeletonElement
          type="title"
          barHeight={Math.floor(Math.random() * (100 - minimum)) + "%"}
        />
        <SkeletonElement
          type="text"
          barHeight={Math.floor(Math.random() * (100 - minimum)) + "%"}
        />
        <SkeletonElement
          type="title"
          barHeight={Math.floor(Math.random() * (100 - minimum)) + "%"}
        />
        <SkeletonElement
          type="text"
          barHeight={Math.floor(Math.random() * (100 - minimum)) + "%"}
        />
        <SkeletonElement
          type="title"
          barHeight={Math.floor(Math.random() * (100 - minimum)) + "%"}
        />
        <SkeletonElement
          type="text"
          barHeight={Math.floor(Math.random() * (100 - minimum)) + "%"}
        />
        <SkeletonElement
          type="title"
          barHeight={Math.floor(Math.random() * (100 - minimum)) + "%"}
        />
        <SkeletonElement
          type="text"
          barHeight={Math.floor(Math.random() * (100 - minimum)) + "%"}
        />
        <SkeletonElement
          type="title"
          barHeight={Math.floor(Math.random() * (100 - minimum)) + "%"}
        />
        <SkeletonElement
          type="text"
          barHeight={Math.floor(Math.random() * (100 - minimum)) + "%"}
        />
      </div>
      <div className="skeleton__wrapperfooter">
        <SkeletonElement2 type="label" />
        <SkeletonElement2 type="label" />
        <SkeletonElement2 type="label" />
        <SkeletonElement2 type="label" />
        <SkeletonElement2 type="label" />
        <SkeletonElement2 type="label" />
        <SkeletonElement2 type="label" />
        <SkeletonElement2 type="label" />
        <SkeletonElement2 type="label" />
        <SkeletonElement2 type="label" />
        <SkeletonElement2 type="label" />
        <SkeletonElement2 type="label" />
        <SkeletonElement2 type="label" />
        <SkeletonElement2 type="label" />
        <SkeletonElement2 type="label" />
        <SkeletonElement2 type="label" />
        <SkeletonElement2 type="label" />
        <SkeletonElement2 type="label" />
      </div>
    </div>
  );
};

export default SkeletonChart;
