import { useState } from "react";
import "./ThirdPartyApis.css";
import { baseUrl } from "../../utils/baseUrls";
import ApiNotification from "../notifications/ApiNotification";
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import {
  wpConnectModal,
  loggedInReducer,
  userIdReducer,
  nameReducer,
  emailReducer,
  companyReducer,
  industryReducer,
  areaReducer,
  websiteReducer,
  competitorsReducer,
  keywordsReducer,
  targetAudienceReducer,
  currentSubscriptionReducer,
  subscriptionExpiry,
  wordpressUrlReducer,
} from "../../store/Store";

function WordpressApi() {
  const [isMessageSuccessful, setIsMessageSuccessful] = useState(false);
  const [buttonText, setButtonText] = useState("Submit");
  const [formData, setFormData] = useState({
    wordpressURL: "",
    username: "",
    appPassword: "",
  });

  const dispatch = useDispatch();

  const handleModal = () => {
    dispatch(wpConnectModal(false));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Check if the input is for wordpressURL
    if (name === "wordpressURL") {
      // Enforce "https://" and remove trailing slash
      let formattedValue = value.trim().replace(/\/$/, ""); // Remove trailing slash

      // Check if the URL starts with "https://"
      if (!formattedValue.toLowerCase().startsWith("https://")) {
        // If not, add "https://"
        formattedValue = `https://${formattedValue}`;
      }

      // Update the state
      setFormData((prevData) => ({ ...prevData, [name]: formattedValue }));
    } else {
      // For other input fields, update the state normally
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    if (!formData.wordpressURL || !formData.username || !formData.appPassword) {
      alert("All fields are required");
      return;
    }
    e.preventDefault();
    setButtonText("updating...");

    try {
      const response = await fetch(`${baseUrl}/wp-routes/authenticate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          wordpressURL: formData.wordpressURL,
          username: formData.username,
          appPass: formData.appPassword,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        // console.log(data);
        // dispatch(wordpressUrlReducer(data.wordpressUrl));
        // Clear the input fields
        localStorage.setItem("token", data.token);

        //dispatch new token update
        const retrievedToken = localStorage.getItem("token");
        const decode = jwt_decode(retrievedToken);
        dispatch(loggedInReducer(true));
        dispatch(userIdReducer(decode.id));
        dispatch(nameReducer(decode.name));
        dispatch(emailReducer(decode.email));
        dispatch(companyReducer(decode.company));
        dispatch(industryReducer(decode.industry));
        dispatch(areaReducer(decode.area));
        dispatch(websiteReducer(decode.website));
        dispatch(competitorsReducer(decode.competitors));
        dispatch(keywordsReducer(decode.keywords));
        dispatch(targetAudienceReducer(decode.target_audience));
        dispatch(currentSubscriptionReducer(decode.subscription));
        dispatch(subscriptionExpiry(decode.subscriptionExpiry));
        dispatch(wordpressUrlReducer(decode.wordpressUrl));

        //empty formdata
        setFormData({
          wordpressURL: "",
          username: "",
          appPassword: "",
        });
        setButtonText("Submit");
        setIsMessageSuccessful(true);
        setTimeout(() => {
          setIsMessageSuccessful(false);
          // window.location.reload();
          setTimeout(() => {
            // remove modal
            handleModal();
          }, 1000);
        }, 2000);
        // Handle the response accordingly
        // console.log("WordPress authentication successful:", data);
      } else {
        // Handle errors
        console.error("WordPress authentication failed:", response.statusText);
      }
    } catch (error) {
      // Handle other errors
      console.error("Error during WordPress authentication:", error);
    }
  };

  return (
    <div className="thirdparty_apis">
      <div className="thirdparty__apisheader">
        <h6>Connect your Wordpress Account</h6> <p onClick={handleModal}>X</p>
      </div>
      <form onSubmit={handleSubmit}>
        {/* Input fields */}
        <div>
          <label>WordPress URL:</label>
          <p
            style={{
              fontSize: "12px",
              marginBottom: "5px",
              color: "var(--icon-font-color)",
            }}
          >
            Required format: https://yourwebsite.com
          </p>
          <input
            type="text"
            name="wordpressURL"
            value={formData.wordpressURL}
            onChange={handleInputChange}
            placeholder="https://"
          />
        </div>
        <div>
          {" "}
          <label>Username:</label>
          <input
            type="text"
            name="username"
            value={formData.username}
            onChange={handleInputChange}
            placeholder="your wp username"
          />
        </div>
        <div>
          <label>App Password:</label>
          <input
            type="text"
            name="appPassword"
            value={formData.appPassword}
            onChange={handleInputChange}
            placeholder="your wp app password"
          />
        </div>
        <button type="submit">{buttonText}</button>
      </form>
      <p>Facing issues? Watch the video below</p>
      {isMessageSuccessful ? (
        <ApiNotification
          message="Successful!"
          linkColor="#ffffff"
          backGround="#213b20e0"
          // borderColor="#077602"
          customMarginLeft="30px"
        />
      ) : null}
    </div>
  );
}

export default WordpressApi;
