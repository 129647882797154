import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./App.css";
import Keywords from "./components/Keywords";
import LoginPage from "./components/LoginPage";
import PrivateRoutes from "./components/PrivateRoutes";
import { useEffect } from "react";
import Competitors from "./components/Competitors";
import TargetAudience from "./components/TargetAudience";
import Settings from "./components/Settings";
import jwt_decode from "jwt-decode";
import AdminHome from "./components/new_admin/AdminHome";
import AdminHomeDefault from "./components/new_admin/AdminHomeDefault";
import {
  loggedInReducer,
  userIdReducer,
  nameReducer,
  emailReducer,
  companyReducer,
  industryReducer,
  areaReducer,
  websiteReducer,
  competitorsReducer,
  keywordsReducer,
  targetAudienceReducer,
  currentSubscriptionReducer,
  subscriptionExpiry,
  wordpressUrlReducer,
} from "./store/Store";
import Support from "./components/Support";
import EmbeddingTemp from "./components/EmbeddingTemp";

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const isLoggeIn = useSelector((state) => state.user.loggedIn);

  //Redirect homepage to login auth page
  useEffect(() => {
    if (window.location.pathname === "/") {
      navigate("/login", { replace: true });
    }
  }, [navigate]);

  //Set and Get Last page
  localStorage.setItem("lastPage", window.location.pathname);

  // useEffect for controlling protected routes using store
  useEffect(() => {
    try {
      const retrievedToken = localStorage.getItem("token");
      const lastPage = localStorage.getItem("lastPage");

      if (retrievedToken) {
        navigate(lastPage);

        if (location.pathname === "/login") {
          navigate("/admin");
        }
      }
    } catch (err) {
      console.error(err);
    }
  }, [navigate, location.pathname]);

  //control access based on token expiry
  useEffect(() => {
    try {
      const retrievedToken = localStorage.getItem("token");
      const decode = jwt_decode(retrievedToken);
      const currentTime = Date.now() / 1000;
      const tokenExpTime = decode.exp;

      if (retrievedToken && currentTime < tokenExpTime) {
        dispatch(loggedInReducer(true));
      } else {
        dispatch(loggedInReducer(false));
        localStorage.removeItem("token");
        navigate("/login");
      }
      dispatch(loggedInReducer(true));
      dispatch(userIdReducer(decode.id));
      dispatch(nameReducer(decode.name));
      dispatch(emailReducer(decode.email));
      dispatch(companyReducer(decode.company));
      dispatch(industryReducer(decode.industry));
      dispatch(areaReducer(decode.area));
      dispatch(websiteReducer(decode.website));
      dispatch(competitorsReducer(decode.competitors));
      dispatch(keywordsReducer(decode.keywords));
      dispatch(targetAudienceReducer(decode.target_audience));
      dispatch(currentSubscriptionReducer(decode.subscription));
      dispatch(subscriptionExpiry(decode.subscriptionExpiry));
      dispatch(wordpressUrlReducer(decode.wordpressUrl));
    } catch (err) {
      console.log(err);
    }
  }, [dispatch, navigate]);

  return (
    <div className="App">
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/embtemp" element={<EmbeddingTemp />} />
        <Route element={<PrivateRoutes state={isLoggeIn} />}>
          <Route path="/admin" element={<AdminHome />}>
            <Route index element={<AdminHomeDefault />} />
            <Route path="settings" element={<Settings />} />
            <Route path="keywords" element={<Keywords />} />
            <Route path="competitors" element={<Competitors />} />
            <Route path="targetaudience" element={<TargetAudience />} />
            <Route path="support" element={<Support />} />
          </Route>
        </Route>
        {/* <Route path="/signup" element={<SignupChat />} /> */}
      </Routes>
    </div>
  );
}

export default App;
