import { NavLink } from "react-router-dom";
import "./ApiNotification.css";

function ApiNotification({
  message,
  path,
  actionValue,
  linkColor,
  backGround,
  borderColor,
  customMarginLeft,
}) {
  return (
    <div
      className="noapikey__notification"
      style={{ background: backGround, borderLeft: `7px solid ${borderColor}` }}
    >
      <p style={{ color: "#7bab7a", marginLeft: customMarginLeft }}>
        {message}
        {""}
        <NavLink to={path} style={{ color: linkColor }}>
          <b>{actionValue}</b>
        </NavLink>
      </p>
    </div>
  );
}

export default ApiNotification;
