import { useEffect, useState } from "react";
import "./Competitors.css";
import SkeletonChart from "./skeletons/SkeletonChart";
import BarChart from "./new_admin/charts/BarChart";
import LineChart from "./new_admin/charts/LineChart";
import SkeletonElement2 from "./skeletons/SkeletonElement2";
import { useSelector } from "react-redux";

function Competitors() {
  const competitors = useSelector((state) => state.user.competitors);

  // let labels = [
  //   "competitors1",
  //   "competitors2",
  //   "competitors3",
  //   "competitors4",
  //   "competitors5",
  //   "competitors6",
  //   "competitors7",
  //   "competitors8",
  //   "competitors9",
  //   "competitors10",
  // ];

  // let data = [12, 40, 19, 47, 73, 12, 40, 19, 47, 73];

  const [boolValue, setBoolValue] = useState();
  useEffect(() => {
    setBoolValue(true);
  }, [boolValue]);
  return (
    <div className="keywords__page">
      {!boolValue
        ? [1, 2].map((n) => (
            <div key={n}>
              <SkeletonElement2 type="label2" />
              <SkeletonChart />
            </div>
          ))
        : null}
      {/* double section for common keywords */}
      <div className="common__keywordssection">
        <div className="common__keywordssectionchild">
          <p>Common Keywords</p>
          <div>
            <BarChart
              labels={competitors.slice(0, 5).map((item) => item.item)}
              data={competitors
                .slice(0, 5)
                .map((item) => item.data.commonKeywords)}
              label="Competition"
            />
          </div>
        </div>
        <div className="common__keywordssectionchild">
          <p>Adwords Keywords</p>
          <div>
            <BarChart
              labels={competitors.slice(0, 5).map((item) => item.item)}
              data={competitors
                .slice(0, 5)
                .map((item) => item.data.adwordsKeywords)}
              label="Adwords"
            />
          </div>
        </div>
      </div>
      {/* top chart for organic keywords */}
      <div className="competitors__mainchart">
        <p>Organic Keywords</p>{" "}
        <div>
          <LineChart
            labels={competitors.slice(0, 5).map((item) => item.item)}
            data={competitors
              .slice(0, 5)
              .map((item) => item.data.organicKeywords)}
            label="Organic Keywords"
          />
        </div>
      </div>
      {/* bottom for adwords traffic and cost */}
      <div className="common__keywordssection">
        <div className="common__keywordssectionchild">
          <p>Organic Traffic</p>
          <div>
            <BarChart
              labels={competitors.slice(0, 5).map((item) => item.item)}
              data={competitors
                .slice(0, 5)
                .map((item) => item.data.organicTraffic)}
              label="Organic Traffic"
            />
          </div>
        </div>
        <div className="common__keywordssectionchild">
          <p>Organic Cost</p>
          <div>
            <BarChart
              labels={competitors.slice(0, 5).map((item) => item.item)}
              data={competitors
                .slice(0, 5)
                .map((item) => item.data.organicCost)}
              label="Organic Cost"
            />
          </div>
        </div>
      </div>
      {/* <div className="competitors__otherdetails">
        {" "}
        <ul>
          {competitorsData.slice(0, 5).map((comp, i) => (
            <li key={i}>{comp.name}</li>
          ))}
        </ul>
      </div> */}{" "}
      <div className="analyticspage__footer"></div>
    </div>
  );
}

export default Competitors;
