import { useEffect, useState } from "react";
// import "./TargetAudience.css";
import "./Keywords.css";
import SkeletonChart from "./skeletons/SkeletonChart";
import SkeletonElement2 from "./skeletons/SkeletonElement2";

function TargetAudience() {
  const [boolValue, setBoolValue] = useState();
  useEffect(() => {
    setBoolValue(false);
  }, [boolValue]);
  return (
    <div className="keywords__page">
      {!boolValue
        ? [1, 2].map((n) => (
            <div key={n}>
              <SkeletonElement2 type="label2" />
              <SkeletonChart />
            </div>
          ))
        : null}
    </div>
  );
}

export default TargetAudience;
