import { Bar } from "react-chartjs-2";
// import { Chart as ChartJS } from "chart.js/auto";

function BarChart({ labels, data, label }) {
  return (
    <Bar
      data={{
        labels,
        datasets: [
          {
            label: `${label}`,
            data,
            backgroundColor: ["#d9d9d9", "#7bab7a"],
            borderColor: "#182929",
            borderWidth: 1,
            borderRadius: 50,
            barThickness: 10,
            responsive: true,
          },
        ],
      }}
      //   height={400}
      //   width={600}
      options={{ maintainAspectRatio: false }}
    />
  );
}

export default BarChart;
