import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./LoginPage.css";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import loadcircle from "../assets/loadcircle.gif";
import loadcircle2 from "../assets/loadcircle2.gif";
// import loadcircle_black from "../assets/loadcircle_black.gif";
import { useDispatch } from "react-redux";
import jwt_decode from "jwt-decode";
import {
  loggedInReducer,
  userIdReducer,
  nameReducer,
  emailReducer,
  companyReducer,
  industryReducer,
  areaReducer,
  websiteReducer,
  competitorsReducer,
  keywordsReducer,
  targetAudienceReducer,
  currentSubscriptionReducer,
  subscriptionExpiry,
  wordpressUrlReducer,
} from "../store/Store";
// const baseUrl = "https://mark-server.onrender.com";
// const baseUrl = "http://localhost:4000";
// const homepage = "http://localhost:4000";
// const homepage = "https://meetmark.ai/";

import { baseUrl, homepageUrl } from "../utils/baseUrls";

function LoginPage() {
  const [uemail, setEmail] = useState("");
  const [upassword, setPassword] = useState("");
  const navigate = useNavigate();
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);

  const dispatch = useDispatch();

  const handelLogin = async (e) => {
    // console.log("working");
    e.preventDefault();
    setButtonLoader(true);
    try {
      const url = await fetch(`${baseUrl}/access/signin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: uemail, password: upassword }),
      });

      if (url.ok) {
        const data = await url.json();
        localStorage.setItem("token", data.token);
        setTimeout(() => {
          setButtonLoader(false);
          setIsLoggingIn(true);
        }, 2000);
        dispatch(loggedInReducer(true));

        //decode and set userData
        const retrievedToken = localStorage.getItem("token");
        const decode = jwt_decode(retrievedToken);
        dispatch(userIdReducer(decode.id));
        dispatch(nameReducer(decode.name));
        dispatch(emailReducer(decode.email));
        dispatch(companyReducer(decode.company));
        dispatch(industryReducer(decode.industry));
        dispatch(areaReducer(decode.area));
        dispatch(websiteReducer(decode.website));
        dispatch(competitorsReducer(decode.competitors));
        dispatch(keywordsReducer(decode.keywords));
        dispatch(targetAudienceReducer(decode.target_audience));
        dispatch(currentSubscriptionReducer(decode.subscription));
        dispatch(subscriptionExpiry(decode.subscriptionExpiry));
        dispatch(wordpressUrlReducer(decode.wordpressUrl));
        setTimeout(() => {
          navigate("/admin");
          setIsLoggingIn(false);
        }, 3000);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="homepage__main">
      <div className="login__header">
        <Link to={homepageUrl}>
          <KeyboardBackspaceIcon style={{ color: "gray" }} />
        </Link>
      </div>
      <div className="login__body">
        <div className="login__left">
          {/* <h3>Welcome back!</h3> */}
          <h2 style={{ fontWeight: "800", marginBottom: "30px" }}>
            Welcome <span>Back</span>
          </h2>
          <form action="/login" onSubmit={handelLogin}>
            <input
              type="text"
              name="email"
              placeholder="Email"
              value={uemail}
              onChange={(e) => setEmail(e.target.value)}
              required
            />

            <input
              type="password"
              name="password"
              placeholder="Password"
              value={upassword}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button className="submit__button">
              {buttonLoader ? (
                <img
                  src={loadcircle}
                  alt="Logging in"
                  style={{
                    objectFit: "contain",
                    width: "15px",
                    filter: "grayscale(20%)",
                  }}
                />
              ) : (
                "Login"
              )}{" "}
            </button>
          </form>

          <div className="signup__forgotpw">
            <p style={{ color: "rgb(15, 181, 84)" }}>Reset password</p>
            <span
              style={{
                display: "flex",
                marginTop: "10px",
              }}
            >
              No account?
              <p style={{ color: "rgb(15, 181, 84)", marginLeft: "5px" }}>
                <Link
                  to={`${homepageUrl}/#signup__chat`}
                  style={{ color: "rgb(15, 181, 84)", textDecoration: "none" }}
                >
                  Create one
                </Link>
              </p>
            </span>
            {/* <Link
              to="/signup"
              style={{ textDecoration: "none", color: "gray" }}
            >
              <p>Signup</p>
            </Link> */}
          </div>
        </div>
      </div>
      {/* <div className="login__right">
        <img
          src="https://5dn.leonardo.ai/users/f453e042-2a3d-468a-a87d-a2287f4b64cb/generations/dcc0fb25-c936-4b48-9103-e568b3e16ee1/variations/Default_Girl_pink_hair_sweater_with_collar_bunny_ears_glasses_portait_0_dcc0fb25-c936-4b48-9103-e568b3e16ee1_1.jpg"
          alt="/"
        />
        <img
          src="https://cdn.leonardo.ai/users/88291eba-76bd-4146-b09d-93913a9b638f/generations/c382045b-1d77-4199-91b1-37b82b76ca72/variations/Default_Cyber_punk_girl_fantasy_world_dark_background_overwatch_clean_0_c382045b-1d77-4199-91b1-37b82b76ca72_1.jpg"
          alt="/"
        />
      </div> */}

      {isLoggingIn && (
        <div className="login__overlayloader">
          {/* <p>Preparing your Dashboard</p> */}
          <img
            src={loadcircle2}
            alt="..."
            style={{
              objectFit: "contain",
              width: "25px",
              marginTop: "25px",
            }}
          />
        </div>
      )}
    </div>
  );
}

export default LoginPage;
