import "./AdminHomeDefault.css";
import Arrow_left from "../../assets/newadmin/Arrow_left.png";
import robot_icon from "../../assets/newadmin/robot_icon2.png";
import BarChart from "./charts/BarChart";
import PieChart from "./charts/PieChart";
import { Link } from "react-router-dom";
// import { useState } from "react";
import { useSelector } from "react-redux";

function AdminHomeMainBodyCenter() {
  const keywords = useSelector((state) => state.user.keywords);
  const competitors = useSelector((state) => state.user.competitors);

  // const keywords = ["keyword1", "keyword2", "keyword3", "keyword4", "keyword5"];
  const competitorsTemp = [
    "Age: Coming soon...",
    "Sex: Coming soon...",
    "Interest: Coming soon...",
    "Location: Coming soon...",
    "Behaviours: Coming soon...",
  ];

  return (
    <>
      {/* top div */}
      <div className="adminhome__defaultfirstdiv">
        <div className="summarychart__maindiv">
          <div className="summarychart__maindivbody">
            <div className="summarychart__maindivbodychart">
              <BarChart
                labels={keywords.map((item) =>
                  item.item.split(" ").slice(0, 2).join(" ")
                )}
                data={keywords.map((item) => item.data.searchVolume)}
                label="Search Volume"
              />
            </div>
            <div className="summarychart__maindivbodyicons">
              <img src={robot_icon} alt="mark" />
            </div>
          </div>
          <div className="summarychart__maindivfooter"></div>
          {/* Competitors   */}
          <Link to="/admin/keywords">
            <img
              src={Arrow_left}
              alt="more"
              className="image__absolutebutton"
            ></img>
          </Link>
        </div>
        <div className="summarychart__maindiv">
          <div className="summarychart__maindivbody">
            <div className="summarychart__maindivbodychart">
              <PieChart
                labels={competitors.slice(0, 5).map((comp) => comp.item)}
                data={competitors
                  .slice(0, 5)
                  .map((comp) => comp.data.commonKeywords)}
                label="Common Keywords"
              />
            </div>
            <div className="summarychart__maindivbodyicons">
              <img src={robot_icon} alt="mark" />
            </div>
          </div>
          <div className="summarychart__maindivfooter"></div>
          {/* Competitors */}{" "}
          <Link to="/admin/competitors">
            <img
              src={Arrow_left}
              alt="more"
              className="image__absolutebutton"
            />
          </Link>
        </div>
      </div>

      {/* bottom div */}
      <div className="adminhome__defaultlastdiv">
        <div>
          <p>
            Recent Keywords{" "}
            <span>
              {" "}
              <Link
                to="/admin/keywords"
                style={{
                  color: "var(--admin-white-tone)",
                  textDecoration: "none",
                }}
              >
                &raquo; See full list{" "}
              </Link>
            </span>
          </p>
          <ul>
            {keywords.slice(0, 5).map((item, index) => (
              <div key={index} className="datalistitems__andbullet">
                <div></div>
                <li
                  className="datalist__items"
                  style={{ textTransform: "capitalize" }}
                >
                  {item.item}
                </li>
              </div>
            ))}
          </ul>
          {/* <p>&raquo;See full list</p> */}
        </div>
        <div></div>
        <div>
          <p>
            Target Audience{" "}
            <span>
              <Link
                to="/admin/competitors"
                style={{
                  color: "var(--admin-white-tone)",
                  textDecoration: "none",
                }}
              >
                &raquo; See full list
              </Link>
            </span>
          </p>
          <ul>
            {competitorsTemp.map((item, index) => (
              <div key={index} className="datalistitems__andbullet">
                <div></div>
                <li className="datalist__items">{item}</li>
              </div>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}

export default AdminHomeMainBodyCenter;
