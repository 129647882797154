import { configureStore, createSlice } from "@reduxjs/toolkit";

//SIGNUP MODAL TOGGLE SLICE
const modalSlice = createSlice({
  name: "modal",
  initialState: {
    signupChatModalState: false,
    usersChatWindowState: false,
    wpConnectModal: false,
    googleConnectModal: false,
    shopifyConnectModal: false,
    subscriptionModal: false,
  },
  reducers: {
    homepageModalState(state) {
      state.signupChatModalState = !state.signupChatModalState;
    },
    userModalState(state) {
      state.usersChatWindowState = !state.usersChatWindowState;
    },
    wpConnectModal(state) {
      state.wpConnectModal = !state.wpConnectModal;
    },
    googleConnectModal(state) {
      state.googleConnectModal = !state.googleConnectModal;
    },
    shopifyConnectModal(state) {
      state.shopifyConnectModal = !state.shopifyConnectModal;
    },
    subscriptionModal(state) {
      state.subscriptionModal = !state.subscriptionModal;
    },
  },
});

//LOGIN SLICE
const userSlice = createSlice({
  name: "user",
  initialState: {
    loggedIn: false,
    userId: "",
    name: "",
    company: "",
    industry: "",
    area: "",
    website: "",
    competitors: [],
    keywords: [],
    target_audience: [],
    email: "",
    subscription: "",
    subscriptionExpiry: "",
    notifications: ["love"],
    wordpressUrl: "",
  },
  reducers: {
    loggedInReducer(state, action) {
      state.loggedIn = action.payload;
    },
    userIdReducer: (state, action) => {
      state.userId = action.payload;
    },
    nameReducer(state, action) {
      state.name = action.payload;
    },
    emailReducer: (state, action) => {
      state.email = action.payload;
    },
    companyReducer(state, action) {
      state.company = action.payload;
    },
    industryReducer(state, action) {
      state.industry = action.payload;
    },
    areaReducer(state, action) {
      state.area = action.payload;
    },
    websiteReducer(state, action) {
      state.website = action.payload;
    },
    competitorsReducer(state, action) {
      state.competitors = action.payload;
    },
    keywordsReducer(state, action) {
      state.keywords = action.payload;
    },
    targetAudienceReducer(state, action) {
      state.target_audience = action.payload;
    },
    notificationsReducer(state, action) {
      state.notifications = action.payload;
    },
    currentSubscriptionReducer: (state, action) => {
      state.subscription = action.payload;
    },
    subscriptionExpiry: (state, action) => {
      state.subscriptionExpiry = action.payload;
    },
    wordpressUrlReducer: (state, action) => {
      state.wordpressUrl = action.payload;
    },
  },
});

//ChatHistorySlice
const chatHistorySlice = createSlice({
  name: "chatHistory",
  initialState: {
    history: [
      {
        response: `Hi! How can I serve you today? `,
      },
    ],
  },
  reducers: {
    updateHistory: (state, action) => {
      state.history.push(action.payload);
    },
  },
});

//USER ACTIONS EXPORT
export const {
  loggedInReducer,
  userIdReducer,
  nameReducer,
  emailReducer,
  companyReducer,
  industryReducer,
  areaReducer,
  websiteReducer,
  competitorsReducer,
  keywordsReducer,
  targetAudienceReducer,
  currentSubscriptionReducer,
  subscriptionExpiry,
  notificationsReducer,
  wordpressUrlReducer,
} = userSlice.actions;

//REDUCERS EXPORTS
export const {
  homepageModalState,
  userModalState,
  wpConnectModal,
  googleConnectModal,
  shopifyConnectModal,
  subscriptionModal,
} = modalSlice.actions;

//EXPORT HISTORY ACTIONS
export const { updateHistory } = chatHistorySlice.actions;

//STORE CONFIGURE EXPORTS
const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    modal: modalSlice.reducer,
    chatHistory: chatHistorySlice.reducer,
  },
});

export default store;
