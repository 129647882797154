import "./AdminRightSideBar.css";
import google_icon from "../../assets/newadmin/google_icon.png";
import wordpress from "../../assets/newadmin/wordpress.png";
import shopify from "../../assets/newadmin/shopify.png";
import diamond from "../../assets/newadmin/diamond.png";
import greenCheck from "../../assets/newadmin/greenCheck.png";
import grayedCheck from "../../assets/newadmin/grayedCheck.png";
// import profileImage from "../../assets/newadmin/avatar/placeholder.jpg";
// import Send_hor_fill from "../../assets/newadmin/Send_hor_fill.png";
import ChatSpace from "../chatarea/ChatSpace";
import { useSelector } from "react-redux";

// import { useState } from "react";

function AdminRightSideBar() {
  const isSubscribed = false;
  const isGoogleVerified = false;
  // const isWordpressVerified = false;
  const isShopifyVerified = false;

  const getWordpressUrl = useSelector((state) => state.user.wordpressUrl);

  return (
    <div className="adminright__sidebar">
      <div>
        <div className="acc__hightlights">
          <img src={google_icon} alt="google" />
          <p>Google My Business</p>
          <img src={isGoogleVerified ? greenCheck : grayedCheck} alt="check" />
        </div>
        <div className="acc__hightlights">
          <img src={diamond} alt="google" />
          <p>Subscribed</p>
          <img src={isSubscribed ? greenCheck : grayedCheck} alt="check" />
        </div>
        <div className="acc__hightlights">
          <img src={wordpress} alt="google" />
          <p>Connect Wordpress</p>
          <img src={getWordpressUrl ? greenCheck : grayedCheck} alt="check" />
        </div>
        <div className="acc__hightlights">
          <img src={shopify} alt="google" />
          <p>Connect Shopify</p>
          <img src={isShopifyVerified ? greenCheck : grayedCheck} alt="check" />
        </div>
      </div>
      <div className="adminchat__interfacesection">
        <ChatSpace />
      </div>
    </div>
  );
}

export default AdminRightSideBar;
