import { useState } from "react";
import ApiNotification from "./notifications/ApiNotification";
import "./Support.css";
// const baseUrl = "https://inkinstant-server.onrender.com";
import { baseUrl } from "../utils/baseUrls";

function Support() {
  const [senderName, setSenderName] = useState("");
  const [senderMail, setSenderMail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [resMessage, setResMessage] = useState("");
  const [isMessageSuccessful, setIsMessageSuccessful] = useState(false);
  const [buttonValue, setButtonValue] = useState("Get in touch");

  const handleSubmit = async (e) => {
    try {
      if (!senderName) {
        return;
      }

      if (senderMail && subject && message && senderName) {
        setButtonValue("Sending");
      }
      e.preventDefault();
      const response = await fetch(`${baseUrl}/users/support`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          name: senderName,
          email: senderMail,
          subject,
          message,
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        setResMessage(data.message);
      } else {
        setIsMessageSuccessful(true);
        setTimeout(() => {
          setIsMessageSuccessful(false);
        }, 5000);
        setResMessage(data.message);
        setSenderName("");
        setSenderMail("");
        setMessage("");
        setSubject("");
        setButtonValue("Get in touch");
      }
    } catch (err) {
      throw new Error(err);
    }
  };

  return (
    <div className="support___page">
      {/* <h2>Reach Out to Us </h2> */}
      <p>
        Require Assistance or Have a Request? Connect with Our Welcoming Team,
        and We'll Respond Promptly!
      </p>
      <form>
        <input
          type="text"
          placeholder="Name"
          value={senderName}
          onChange={(e) => {
            setSenderName(e.target.value);
            setResMessage("");
          }}
          required
        />
        <input
          type="email"
          placeholder="you@mail.com"
          value={senderMail}
          onChange={(e) => {
            setSenderMail(e.target.value);
            setResMessage("");
          }}
          required
        />
        <input
          type="text"
          placeholder="Subject"
          value={subject}
          onChange={(e) => {
            setSubject(e.target.value);
            setResMessage("");
          }}
          required
        />
        <textarea
          type="text"
          placeholder="Message"
          value={message}
          onChange={(e) => {
            setMessage(e.target.value);
            setResMessage("");
          }}
          required
        />
        <input
          type="button"
          value={buttonValue}
          onClick={handleSubmit}
          required
        />
        <p
          style={
            resMessage === "Please fill in all fileds!"
              ? { color: "red" }
              : { color: "#858892" }
          }
        >
          {resMessage}
        </p>
      </form>

      {isMessageSuccessful ? (
        <ApiNotification
          message="Successful!"
          linkColor="#ffffff"
          backGround="#213b20e0"
          // borderColor="#077602"
          customMarginLeft="30px"
        />
      ) : null}
    </div>
  );
}

export default Support;
