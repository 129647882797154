import { Outlet } from "react-router-dom";
import AdminHeader from "./AdminHeader";
import "./AdminHome.css";
import AdminRightSideBar from "./AdminRightSideBar";
import AdminSideMenu from "./AdminSideMenu";
import MobileMenu from "./MobileMenu";

function AdminHome() {
  return (
    <>
      <MobileMenu />
      <div className="admin__home">
        <AdminSideMenu />
        <div className="adminhome__main">
          <AdminHeader />
          <div className="adminhomemain__body">
            <div className="adminhomemain__bodycenter">
              <Outlet />
            </div>

            <AdminRightSideBar />
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminHome;
