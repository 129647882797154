import { useEffect, useRef, useState } from "react";
import "./ChatSpace.css";
// import { Avatar } from "@mui/material";
// import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import typingLoader from "../../assets/pendulum_loader.gif";
import circleLoader from "../../assets/loadcircle.gif";
import { useDispatch, useSelector } from "react-redux";
import { updateHistory } from "../../store/Store";

// import { homepageModalState } from "../../store/Store.js";
import { baseUrl } from "../../utils/baseUrls.js";

// const baseUrl = "https://mark-server.onrender.com";
// const baseUrl = "http://localhost:4000";

function ChatSpace() {
  //use Dispatch for redux store
  // const dispatch = useDispatch();
  const usersName = useSelector((state) => state.user.name);
  const company = useSelector((state) => state.user.company);
  const industry = useSelector((state) => state.user.industry);
  const area = useSelector((state) => state.user.area);
  const website = useSelector((state) => state.user.website);
  const competitors = useSelector((state) => state.user.competitors);
  const keywords = useSelector((state) => state.user.keywords);
  const email = useSelector((state) => state.user.email);
  const subscription = useSelector((state) => state.user.subscription);

  const historyStore = useSelector((state) => state.chatHistory.history);
  // const [history, setHistory] = useState([
  //   {
  //     response: `Hi ${usersName}! How can I serve you today? `,
  //   },
  // ]);

  // conversation history
  const [conversationHistory, setConversationHistory] = useState([
    // { role: "user", content: "Can you tell me about love?" },
    // { role: "assistant", content: "Love is a beautiful thing" },
  ]);

  //set stream text input
  const [streamedResponse, setStreamedResponse] = useState("");

  const [inputVal, setInputval] = useState("");
  const [loading, setLoading] = useState(false);
  const chatContainer = useRef(null);
  const dispatch = useDispatch();

  //AUTO SCROLL DOWN TO NEW CHAT
  useEffect(() => {
    if (chatContainer.current) {
      chatContainer.current.scrollTo({
        top: chatContainer.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [loading, streamedResponse]);

  //HANDLE SUBMIT
  function handleSubmit(e) {
    e.preventDefault();
    if (loading) {
      return;
    }
    if (!inputVal) {
      alert("Must add an input!");
      return;
    }
    setLoading(true);
    // setHistory((prevItems) => [...prevItems, { prompt: inputVal }]);
    if (streamedResponse) {
      dispatch(updateHistory({ response: streamedResponse }));
      setStreamedResponse("");
      setConversationHistory((prevHistory) => [
        ...prevHistory,
        { role: "user", content: inputVal },
        {
          role: "assistant",
          content: streamedResponse,
        },
      ]);
    }

    if (!streamedResponse) {
      setConversationHistory((prevHistory) => [
        ...prevHistory,
        { role: "user", content: inputVal },
      ]);
    }
    dispatch(updateHistory({ prompt: inputVal }));

    getData();
    setInputval("");
  }

  //send new message from user and also send conversation history
  const getData = async () => {
    try {
      // setLoading(true);
      const url = await fetch(`${baseUrl}/api/usermessage`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          message: inputVal,
          history: conversationHistory,
          userData: {
            user_profile: {
              users_name: usersName,
              email: email,
              company: company,
              industry: industry,
              area: area,
              website_url: website,
              competitors: competitors.map(
                (item) => item.item + ": " + item.data
              ),
              keywords: keywords.map((item) => item.item + ": " + item.data),
              subscription: subscription,
            },
          },
        }),
      });

      //Update UI history;
      const decoder = new TextDecoder("utf-8");
      const reader = url.body.getReader();
      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          break;
        }

        // //Stream response
        setStreamedResponse((prev) => prev + decoder.decode(value));
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="chat_space2">
      <div className="chatspace__head2">
        <p>Ask Mark</p> <span>:)</span>
      </div>

      <div className="chatspace__body2" ref={chatContainer}>
        {historyStore?.map((e, index) => {
          return (
            <div className="promptres__div2" key={index}>
              <div className={e.prompt ? "prompt__div2" : "res__div2"}>
                <p className={e.prompt ? "prompt__2" : "response__2"}>
                  {e.prompt || e?.response}
                </p>
              </div>
            </div>
          );
        })}

        {streamedResponse?.length > 0 ? (
          <div className="promptres__div2">
            <div className="res__div2">
              <p className="response__2">{streamedResponse}</p>
            </div>
          </div>
        ) : null}

        {loading ? (
          <div className="chatspace__istyping22">
            {/* <i>Trip Aide is typing</i> */}
            <img
              src={typingLoader}
              alt="/"
              style={{ width: "45px", marginTop: "20px", opacity: "0.5" }}
            />
          </div>
        ) : null}
      </div>

      <div className="chatspace__footer2">
        <form onSubmit={handleSubmit} className="chatspace__form2">
          <input
            type="text"
            name="text"
            autoComplete="off"
            value={inputVal}
            onChange={(e) => {
              setInputval(e.target.value);
            }}
            placeholder="Write here... "
          />
          {loading ? (
            <img src={circleLoader} alt="loading..." className="chat__load2" />
          ) : (
            <SendIcon className="chat__send2" onClick={handleSubmit} />
          )}
        </form>
        {/* <div className="footer__info2">
        <h6>Mark AI</h6>
        <p>
          By proceeding, you acknowledge and agree to the terms and policy of
          Mark AI.. <a href="mailto:markai@gmail.com">Contact Support</a>
        </p>
      </div> */}
      </div>
    </div>
  );
}

export default ChatSpace;
