import "./AdminSideMenu.css";
import { Link, useLocation } from "react-router-dom";
import pAvatar from "../../assets/newadmin/avatar/placeholder.jpg";
// import Bubble from "../../assets/newadmin/Bubble.png";
import pie_chart from "../../assets/newadmin/pie_chart.png";
import pie_chart_dark from "../../assets/newadmin/pie_chart_dark.png";
import Home_fill from "../../assets/newadmin/Home_fill.png";
import Home_fill_dark from "../../assets/newadmin/Home_fill_dark.png";
import Critical from "../../assets/newadmin/Critical.png";
import Critical_dark from "../../assets/newadmin/Critical_dark.png";
import Chart_alt from "../../assets/newadmin/Chart_alt.png";
import Chart_alt_dark from "../../assets/newadmin/Chart_alt_dark.png";
import Setting_alt_line from "../../assets/newadmin/Setting_alt_line.png";
import Setting_alt_line_dark from "../../assets/newadmin/Setting_alt_line_dark.png";

function AdminSideMenu() {
  const location = useLocation();
  return (
    <div className="newadminside__menu">
      <div className="newadminside__menuheader">
        <h2 style={{ color: "white" }}>Mark</h2>
      </div>
      <div className="newadmin__menuarea">
        <img
          src={pAvatar}
          alt="avatar"
          style={{ borderRadius: "20px" }}
          className="newadmin_avatar"
        />
        <div className="newadminsidebar__menuicon">
          <Link to="/admin">
            {" "}
            <img
              src={location.pathname === "/admin" ? Home_fill_dark : Home_fill}
              alt="home"
              style={
                location.pathname === "/admin"
                  ? {
                      background: "var(--ascent-red)",
                    }
                  : null
              }
            />
          </Link>
          <Link to="competitors">
            <img
              src={
                location.pathname === "/admin-home/competitors"
                  ? Chart_alt_dark
                  : Chart_alt
              }
              alt="chart1"
              style={
                location.pathname === "/admin-home/competitors"
                  ? {
                      background: "var(--ascent-red)",
                    }
                  : null
              }
            />
          </Link>
          <Link to="keywords">
            <img
              src={
                location.pathname === "/admin-home/keywords"
                  ? Critical_dark
                  : Critical
              }
              alt="chart2"
              style={
                location.pathname === "/admin-home/keywords"
                  ? {
                      background: "var(--ascent-red)",
                    }
                  : null
              }
            />
          </Link>
          <Link to="targetaudience">
            <img
              src={
                location.pathname === "/admin-home/audience"
                  ? pie_chart_dark
                  : pie_chart
              }
              alt="chart3"
              style={
                location.pathname === "/admin-home/audience"
                  ? {
                      background: "var(--ascent-red)",
                    }
                  : null
              }
            />
          </Link>
          {/* <img src={Bubble} alt="Bubble" /> */}
          <Link to="settings">
            <img
              src={
                location.pathname === "/admin-home/settings"
                  ? Setting_alt_line_dark
                  : Setting_alt_line
              }
              alt="Settings"
              style={
                location.pathname === "/admin-home/settings"
                  ? {
                      background: "var(--ascent-red)",
                    }
                  : null
              }
            />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default AdminSideMenu;
