import "./Settings.css";
// import googleLogo from "../assets/Google_Logo.png";
// import { baseUrl } from "../utils/baseUrls.js";
import ApiConnectComponent from "./shared/ApiConnectComponent.jsx";
import { useDispatch, useSelector } from "react-redux";
import { wpConnectModal } from "../store/Store";
import WordpressApi from "./shared/WordpressApi.jsx";
import wplogo2 from "../assets/newadmin/socialmediaicons/wplogo2.png";
import google_logo2 from "../assets/newadmin/socialmediaicons/google_logo2.png";
import shopify_logo2 from "../assets/newadmin/socialmediaicons/shopify_logo2.png";
import diamondIcon from "../assets/newadmin/diamond.png";

function Settings() {
  const subscriptionTier = useSelector((state) => state.user.subscription);
  const getWordpressUrl = useSelector((state) => state.user.wordpressUrl);
  const wordpressModalState = useSelector(
    (state) => state.modal.wpConnectModal
  );
  const dispatch = useDispatch();

  const handleModal = () => {
    dispatch(wpConnectModal(true));
  };
  return (
    <div className="settings__main">
      <div className="connect__google">
        {/* <h3>Connect Your Google Account</h3>

        <a href={`${baseUrl}/#`} className="authetication__button">
          <img src={googleLogo} alt="" />
          <button>Connect with Google</button>
        </a>
        <div className="googleaccount__info">
          <p>
            <b>Info: </b>
            Update your business data information in real time. Gain insights
            into your business profile's performance and analytics. Increase
            your online visibility and improve your search engine rankings.
          </p>
        </div> */}
      </div>
      <ApiConnectComponent
        title="Google"
        status="Not connected"
        isDefault={true}
        img={google_logo2}
      />
      <ApiConnectComponent
        title="Wordpress"
        status={getWordpressUrl ? "Connected" : "Not connected"}
        popupHandler={handleModal}
        isDefault={getWordpressUrl ? false : true}
        componentDataIfNotDefault={getWordpressUrl}
        img={wplogo2}
      />
      <ApiConnectComponent
        title="Shopify"
        status="Not connected"
        isDefault={true}
        img={shopify_logo2}
      />{" "}
      <ApiConnectComponent
        title="Subscription"
        status={subscriptionTier}
        isDefault={true}
        img={diamondIcon}
      />
      {/* API and subscription modals */}
      {wordpressModalState ? <WordpressApi /> : null}
    </div>
  );
}

export default Settings;
