import { Chart as ChartJS } from "chart.js/auto";
import { Chart } from "react-chartjs-2";

function LineChart({ labels, data, label, fillState = false }) {
  return (
    <Chart
      data={{
        labels,
        datasets: [
          {
            type: "line",
            label: `${label}`,
            data,
            backgroundColor: ["#7bab7a", "#d9d9d9"],
            borderColor: "#d9d9d9",
            borderWidth: 1,
            fill: fillState,
            pointRadius: 1,
            pointHitRadius: 10,
            lineTension: 0.2,
            borderJoinStyle: "miter",
            pointBackgroundColor: "#fff",
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            borderDashOffset: 0.0,
            pointBorderColor: "#7bab7a",
            pointHoverBackgroundColor: "#d9d9d9",
            pointHoverBorderColor: "#d9d9d9",
            pointHoverBorderWidth: 2,
            // borderDash: [5],
            // borderDashOffset: 0.7,
          },
          //   {
          //     type: "bar",
          //     label: "Bar",
          //     backgroundColor: ["#d9d9d9", "#7bab7a"],
          //     borderColor: "#182929",
          //     borderWidth: 1,
          //     // borderRadius: 50,
          //     barThickness: 30,
          //     data,
          //   },
        ],
      }}
      //   height={400}
      //   width={600}
      options={{ maintainAspectRatio: false }}
    />
  );
}

export default LineChart;
