import { useState } from "react";
import "./ApiConnectComponent.css";

function ApiConnectComponent({
  title,
  status,
  popupHandler,
  isDefault = true,
  componentDataIfNotDefault,
  img,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`apiconnect__component ${isOpen ? "open" : "collapsed"}`}>
      <div>
        <div style={{ display: "flex" }}>
          <img
            src={img}
            alt="icon"
            style={{ marginRight: "5px", objectFit: "contain", width: "18px" }}
          />
          <h6 onClick={handleToggle}>
            {title} {isOpen ? " -" : " +"}
          </h6>
        </div>
        <div className="separator"></div>
      </div>
      {/* Additional content goes here */}
      {isOpen ? (
        isDefault ? (
          <div className="content">
            <p style={{ color: "var(--icon-font-color)", marginBottom: "5px" }}>
              Status: {status}
              {/* <span style={{ color: "var(--icon-font-color)" }}></span> */}
            </p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p>
                Click here to {title === "Subscription" ? "update" : "connect"}{" "}
                your {title} {title === "Subscription" ? "" : "account"}
              </p>
              <button onClick={popupHandler}>
                {title === "Subscription" ? "Upgrade" : "Connect"}
              </button>
            </div>
          </div>
        ) : (
          <div className="content">
            <p style={{ color: "var(--icon-font-color)", marginBottom: "5px" }}>
              Status:
              <span
                style={{
                  color: status === "Connected" ? "#4CAF50" : "",
                  marginLeft: "10px",
                }}
              >
                {status}
              </span>
            </p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p
                style={{ fontSize: "14px", color: "var(  --icon-font-color)" }}
              >
                Your {title} is currently active.
                <br />
                <span style={{ color: "var( --admin-white-tone)" }}>
                  {title === "Wordpress"
                    ? `Website: ${componentDataIfNotDefault}`
                    : null}
                </span>
              </p>
              {/* <button onClick={popupHandler}>Connect</button> */}
            </div>
          </div>
        )
      ) : null}
    </div>
  );
}

export default ApiConnectComponent;
