import { useEffect, useState } from "react";
// import "./Keywords.css";
import "./Competitors.css";
import SkeletonChart from "./skeletons/SkeletonChart";
import BarChart from "./new_admin/charts/BarChart";
import LineChart from "./new_admin/charts/LineChart";
import PieChart from "./new_admin/charts/PieChart";
import SkeletonElement2 from "./skeletons/SkeletonElement2";
import { useSelector } from "react-redux";

function Keywords() {
  const keywords = useSelector((state) => state.user.keywords);

  const [boolValue, setBoolValue] = useState();
  useEffect(() => {
    setBoolValue(true);
  }, [boolValue]);
  return (
    <div className="keywords__page">
      {!boolValue
        ? [1, 2].map((n) => (
            <div key={n}>
              <SkeletonElement2 type="label2" />
              <SkeletonChart />
            </div>
          ))
        : null}{" "}
      {/* top chart for organic keywords */}
      <div className="competitors__mainchart">
        <p> Keywords</p>{" "}
        <div>
          <LineChart
            labels={keywords.map((item) =>
              item.item.split(" ").slice(0, 2).join(" ")
            )}
            data={keywords.map((item) => item.data.searchVolume)}
            label="Keywords"
            fillState={true}
          />
        </div>
      </div>
      {/* double section for common keywords */}
      <div className="common__keywordssection">
        <div className="common__keywordssectionchild">
          <p>Position</p>
          <div>
            <PieChart
              labels={keywords
                .slice(0, 5)
                .map((item) => item.item.split(" ").slice(0, 2).join(" "))}
              data={keywords.slice(0, 5).map((item) => item.data.position)}
              fillState={true}
              label="Position"
            />
          </div>
        </div>
        <div className="common__keywordssectionchild">
          <p>Traffic</p>
          <div>
            <BarChart
              labels={keywords
                .slice(0, 5)
                .map((item) => item.item.split(" ").slice(0, 2).join(" "))}
              data={keywords.slice(0, 5).map((item) => item.data.traffic)}
              label="Traffic"
            />
          </div>
        </div>
      </div>
      {/* bottom for adwords traffic and cost */}
      <div className="common__keywordssection">
        <div className="common__keywordssectionchild">
          <p>CPC (Cost Per Click)</p>
          <div>
            <BarChart
              labels={keywords
                .slice(0, 5)
                .map((item) => item.item.split(" ").slice(0, 2).join(" "))}
              data={keywords.slice(0, 5).map((item) => item.data.cpc)}
              label="CPC"
            />
          </div>
        </div>
        <div className="common__keywordssectionchild">
          <p>Competition</p>
          <div>
            <BarChart
              labels={keywords
                .slice(0, 5)
                .map((item) => item.item.split(" ").slice(0, 2).join(" "))}
              data={keywords.slice(0, 5).map((item) => item.data.competition)}
              label="Competition"
            />
          </div>
        </div>
      </div>
      <div className="analyticspage__footer"></div>
    </div>
  );
}

export default Keywords;
