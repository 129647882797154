import "./AdminHeader.css";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Bell_pin_true from "../../assets/newadmin/Bell_pin_true.png";
import Bell_pin_false from "../../assets/newadmin/Bell_pin_false.png";
import Chat_alt_3 from "../../assets/newadmin/Chat_alt_3.png";
import Search from "../../assets/newadmin/Search.png";
import logout from "../../assets/newadmin/login_icon.png";
import { useDispatch, useSelector } from "react-redux";
import loadcircle from "../../assets/loadcircle.gif";
import {
  loggedInReducer,
  userIdReducer,
  nameReducer,
  emailReducer,
  companyReducer,
  industryReducer,
  areaReducer,
  websiteReducer,
  competitorsReducer,
  keywordsReducer,
  targetAudienceReducer,
  currentSubscriptionReducer,
  subscriptionExpiry,
  wordpressUrlReducer,
} from "../../store/Store";

function AdminHeader() {
  // const [isNotification, setIsNotification] = useState(true);
  const [islogginOut, setIsLoggingOut] = useState(false);
  const usersName = useSelector((state) => state.user.name);
  const notifications = useSelector((state) => state.user.notifications);

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //handle logout && destroy session

  const handleLogout = () => {
    setIsLoggingOut(true);

    // after successful session removal perform other functions and update store
    localStorage.removeItem("token");
    setTimeout(() => {
      dispatch(loggedInReducer(false));
      dispatch(userIdReducer(""));
      dispatch(nameReducer(""));
      dispatch(emailReducer(""));
      dispatch(companyReducer(""));
      dispatch(industryReducer(""));
      dispatch(areaReducer(""));
      dispatch(websiteReducer(""));
      dispatch(competitorsReducer([]));
      dispatch(keywordsReducer([]));
      dispatch(targetAudienceReducer([]));
      dispatch(currentSubscriptionReducer(""));
      dispatch(subscriptionExpiry(""));
      dispatch(wordpressUrlReducer(""));
      navigate("/login");
    }, 3000);
    // } catch (err) {

    //   console.log("Error found: Logout error");
    // }
  };

  //set time of the day and hype text
  const [activeHypeText, setHypeText] = useState("");

  const [dynamicDayText, setDynamicDayText] = useState([
    "Rise and shine! Your return illuminates this digital landscape",
    "Today is full of opportunities, make it awesome",
    "Unlock your creativity and unleash your inner genius",
    "Let your light shine upon the world",
    "The early bird gets the worm, and you're the early bird!",
    "A brand new day, a brand new chance to succeed",
    "Morning is the best time to set your goals",
    "Make the most of this beautiful morning",
    "A cup of coffee and you're ready to conquer the day",
    "Success starts with a positive mindset in the morning",
  ]);
  const [greeting, setGreeting] = useState("");

  useEffect(() => {
    const currentTime = new Date();
    function checkCurrentTime() {
      const timeInHours = currentTime.getHours();

      if (timeInHours >= 0 && timeInHours < 12) {
        setGreeting("🎯Good morning");
      } else if (timeInHours >= 12 && timeInHours < 17) {
        setGreeting("🔥Good afternoon");
        setDynamicDayText([
          "The possibilities are endless, let's dive in",
          "The digital landscape awaits your brilliance",
          "Together, we can conquer the rest of the day",
          "Keep up the great work!",
          "Fuel your passion, and the afternoon will be a breeze",
          "Afternoons are for pushing boundaries",
          "Step into the afternoon with confidence",
          "Your afternoon is what you make it",
          "Harness your potential and make the most of the day",
          "Success is a mindset, and it's always afternoon somewhere",
        ]);
      } else {
        setGreeting("🥱Good evening");
        setDynamicDayText([
          "Evenings are sometimes great for work, unleash your creativity here",
          "Embrace the night and ignite your imagination",
          "Adventure awaits, get ready for the journey",
          "Reflect on your accomplishments today",
          "As the day winds down, let your inspiration soar",
          "Evening is a time for relaxation and rejuvenation",
          "The evening is yours to own",
          "Your hard work in the evening sets the tone for tomorrow",
          "Evenings are magical, make the most of them",
          "In the quiet of the evening, find your focus",
        ]);
      }
    }

    checkCurrentTime();
  }, []);

  //hype man
  useEffect(() => {
    try {
      (function ranHype() {
        setHypeText(
          `${dynamicDayText[Math.floor(Math.random() * dynamicDayText.length)]}`
        );
      })();
    } catch (err) {
      console.log(err);
    }
  }, [dynamicDayText]);

  return (
    <div className="admin__header">
      {/* Dynamically set page titles*/}
      <div className="adminheader__center">
        {location.pathname === "/admin" ? (
          <h3>
            Dashboard{" "}
            <span>
              {greeting} <span>{usersName}</span>, {activeHypeText}
            </span>
          </h3>
        ) : location.pathname === "/admin/settings" ? (
          <h3>Settings</h3>
        ) : location.pathname === "/admin/competitors" ? (
          <h3>
            Competitors <span>See who you are up against</span>
          </h3>
        ) : location.pathname === "/admin/keywords" ? (
          <h3>
            Keywords <span>Understand the best keywords your niche</span>
          </h3>
        ) : location.pathname === "/admin/support" ? (
          <h3>Support</h3>
        ) : (
          <h3>Target Audience </h3>
        )}
      </div>
      <div className="adminheader__right">
        <div className="adminmenu__icons">
          <img
            src={notifications?.length > 0 ? Bell_pin_true : Bell_pin_false}
            alt="notification"
          />

          <Link to="support">
            <img src={Chat_alt_3} alt="message" />
          </Link>
          <img src={Search} alt="search" />
          {islogginOut ? (
            <img
              src={loadcircle}
              alt="logout"
              style={{ opacity: "0.45", objectFit: "contain", width: "18px" }}
            />
          ) : (
            <img
              src={logout}
              alt="logout"
              onClick={handleLogout}
              style={{ objectFit: "contain", width: "20px" }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default AdminHeader;
