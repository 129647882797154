import { useState } from "react";

function EmbeddingTemp() {
  const [text, setText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState("");

  const handleTextChange = (event) => {
    setResponse("");
    setText(event.target.value);
  };

  const handlePostRequest = async () => {
    if (!text || text.length < 20) {
      alert("Please enter text with context!");
      return;
    }
    setIsLoading(true);
    try {
      const response = await fetch(
        "https://test-wnl1.onrender.com/embeddings/embedd",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            text: text,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        // console.log(data);
        setText("");
        // alert("Data update successful");
        setResponse("Successful. Embedding Index ID: " + data._id);
        setIsLoading(false);
      } else {
        const data = await response.json();
        // console.log(data.error);
        setResponse(data.error);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error making POST request:", error);
    }
  };

  return (
    <div
      style={{
        // color: "black",
        background: "whitesmoke",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        padding: "10px 15px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          padding: "10px 15px",
          width: "50%",
          height: "100%",
        }}
      >
        <h4
          style={{
            textAlign: "left",
            marginBottom: "10px",
          }}
        >
          Embedding Topic
        </h4>{" "}
        <textarea
          placeholder="Enter text here"
          value={text}
          onChange={handleTextChange}
          style={{
            width: "100%",
            padding: "10px 15px",
            height: "30%",
            color: "black",
          }}
        />
        <br />
        {isLoading ? (
          <button>Processing...</button>
        ) : (
          <button onClick={handlePostRequest}>Submit</button>
        )}
        {response && (
          <div style={{ textAlign: "left", marginTop: "15px" }}>
            <h6 style={{ color: "red", fontSize: "14px" }}>Response:</h6>
            <pre>{response}</pre>
          </div>
        )}
      </div>
    </div>
  );
}

export default EmbeddingTemp;
